import { AUTHORITIES } from '@rokita/permissions/src/plugin/MyAccount.plugin';

import { CONSENTS_REGISTER } from 'Component/Consents/Consents.config';
import ConsentsQuery from 'Query/Consents.query';
import MyAccountQuery from 'Query/MyAccount.query';
import VerificationQuery from 'Query/Verification.query';
import { MyAccountDispatcher as SourceMyAccountDispatcher } from 'SourceStore/MyAccount/MyAccount.dispatcher';
import { CART_TOTALS } from 'Store/Cart/Cart.reducer';
import {
    updateCustomerDetails,
    updateCustomerPasswordForgotStatus,
    updateCustomerSignInStatus,
    updateIsLoading,
    updateIsLocked,
} from 'Store/MyAccount/MyAccount.action';
import { CUSTOMER, ONE_MONTH_IN_SECONDS } from 'Store/MyAccount/MyAccount.dispatcher';
import { showNotification } from 'Store/Notification/Notification.action';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import { TRADE_AGREEMENT_CART_TOTALS } from 'Store/TradeAgreementCart/TradeAgreementCart.reducer';
import {
    deleteSessionToken,
    getAuthorizationToken,
    getSessionToken,
    isSignedIn,
    setAuthorizationToken,
    setSessionToken,
} from 'Util/Auth';
import { AUTH_TOKEN, SESSION_TOKEN } from 'Util/Auth/Token';
import BrowserDatabase from 'Util/BrowserDatabase';
import { CART_ID } from 'Util/Cart/Token';
import { COMPARE_UID } from 'Util/Compare/Compare';
import { prepareMutation, prepareQuery } from 'Util/Query';
import { executePost, fetchMutation, getErrorMessage } from 'Util/Request';
import { getErrorCategory } from 'Util/Request/Error';
import { GRAPHQL_AUTHENTICATION, wait } from 'Util/Request/Request';
import { LoginAsUserError } from 'Util/Sentry/Error';
import SessionDatabase from 'Util/SessionDatabase';

export * from 'SourceStore/MyAccount/MyAccount.dispatcher';

export const AttributesDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Attributes/Attributes.dispatcher'
);

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
);

export const TradeAgreementCartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/TradeAgreementCart/TradeAgreementCart.dispatcher'
);

export const SalesDepartmentDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/SalesDepartment/SalesDepartment.dispatcher'
);

export const PartnersDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Partners/Partners.dispatcher'
);

/** @namespace RokitaBasic/Store/MyAccount/Dispatcher */
export class MyAccountDispatcher extends SourceMyAccountDispatcher {
    token = null;

    showTwoFactorVerification = false;

    async checkConsents(dispatch) {
        try {
            const query = ConsentsQuery.getConsents(CONSENTS_REGISTER);

            const { consents } = await executePost(prepareQuery(query));

            return consents;
        } catch (error) {
            dispatch(showNotification('error', getErrorMessage(error)));
        }
    }

    async acceptedConsents(options, dispatch) {
        try {
            const mutation = ConsentsQuery.acceptConsentsOnRegister(options);

            return fetchMutation(mutation);
        } catch (error) {
            dispatch(showNotification('error', getErrorMessage(error)));
        }
    }

    async generateVerificationToken(dispatch) {
        try {
            await fetchMutation(VerificationQuery.generateTFVToken());
            dispatch(showNotification('success', __('Your code has been sent to your email address!')));
        } catch (error) {
            dispatch(showNotification('error', getErrorMessage(error)));
        }
    }

    requestCustomerData(dispatch) {
        const query = MyAccountQuery.getCustomerQuery();

        const customer = BrowserDatabase.getItem(CUSTOMER) || {};

        if (customer.id) {
            dispatch(updateCustomerDetails(customer));
        }

        executePost(prepareQuery([query])).then(
            /** @namespace RokitaBasic/Store/MyAccount/Dispatcher/MyAccountDispatcher/requestCustomerData/executePost/then */
            ({ customer }) => {
                if (!getAuthorizationToken()) {
                    return;
                }

                dispatch(updateIsLocked(false));
                dispatch(updateCustomerDetails(customer));
                BrowserDatabase.setItem(customer, CUSTOMER, ONE_MONTH_IN_SECONDS);
                SalesDepartmentDispatcher.then(({ default: dispatcher }) => dispatcher.getSalesDepartment(dispatch));
                PartnersDispatcher.then(({ default: dispatcher }) => dispatcher.getPartners(dispatch));
                AttributesDispatcher.then(
                    /** @namespace RokitaBasic/Component/Router/Container/mapDispatchToProps/TradeAgreementCartDispatch/then */
                    ({ default: dispatcher }) => dispatcher.handleData(dispatch)
                );
            },
            /** @namespace RokitaBasic/Store/MyAccount/Dispatcher/MyAccountDispatcher/requestCustomerData/executePost/then/catch */
            (error) => {
                if (getErrorCategory(error) === GRAPHQL_AUTHENTICATION) {
                    dispatch(updateIsLocked(true));
                }
                dispatch(showNotification('error', getErrorMessage(error)));
            }
        );
    }

    async loginAsUser(hash, dispatch) {
        try {
            const { loginAsUser: { token } = {} } = await executePost(
                prepareQuery(MyAccountQuery.getLoginAsUser(hash))
            );

            if (!token) {
                return Promise.reject(new LoginAsUserError('Token is invalid'));
            }

            setAuthorizationToken(token);

            await this.initialCart(dispatch, { fetchCart: true });
            await this.requestCustomerData(dispatch);

            if (getSessionToken()) {
                deleteSessionToken();
            }

            dispatch(updateCustomerSignInStatus(true));
            dispatch(updateIsLoading(false));
            dispatch(hideActiveOverlay());
            dispatch(showNotification('success', __('You are successfully logged in!')));
        } catch (error) {
            return Promise.reject(new LoginAsUserError('Token is invalid'));
        }
    }

    async signIn(options, dispatch) {
        if (!this.token) {
            this.clearStorage();

            const {
                generateCustomerToken: { token, show_two_factor_verification = false },
            } = await fetchMutation(MyAccountQuery.getSignInMutation(options));

            this.token = token;

            if (show_two_factor_verification) {
                this.showTwoFactorVerification = show_two_factor_verification;

                setSessionToken(token);
                return true;
            }

            setAuthorizationToken(token);
        } else {
            if (this.showTwoFactorVerification) {
                return true;
            }

            setAuthorizationToken(this.token);
        }

        await this.initialCart(dispatch, { fetchCart: true });
        await this.requestCustomerData(dispatch);

        if (getSessionToken()) {
            deleteSessionToken();
        }

        dispatch(updateIsLoading(false));
        dispatch(hideActiveOverlay());
        dispatch(updateCustomerSignInStatus(true));
        dispatch(showNotification('success', __('You are successfully logged in!')));

        this.showTwoFactorVerification = false;
        this.token = null;

        return false;
    }

    async verification(options = {}, dispatch) {
        try {
            await executePost(prepareQuery(VerificationQuery.validateTFVToken(options)));
            await executePost(prepareMutation(VerificationQuery.saveLastLoggedInDate()));

            setAuthorizationToken(getSessionToken());
            deleteSessionToken();

            await this.initialCart(dispatch, { fetchCart: true });
            await this.requestCustomerData(dispatch);

            dispatch(updateIsLoading(false));
            dispatch(hideActiveOverlay());
            dispatch(updateCustomerSignInStatus(true));
            dispatch(showNotification('success', __('You are successfully logged in!')));
        } catch (error) {
            dispatch(showNotification('error', getErrorMessage(error)));
        }
    }

    async logout(authTokenExpired = false, isWithNotification = true, dispatch) {
        if (authTokenExpired) {
            if (isWithNotification) {
                dispatch(showNotification('error', __('Your session is over, you are logged out!')));
            }
        } else {
            if (isSignedIn()) {
                await wait(fetchMutation(MyAccountQuery.getRevokeAccountToken()), 100);
            }

            if (isWithNotification) {
                dispatch(showNotification('success', __('You are successfully logged out!')));
            }
        }

        this.clearStorage();

        dispatch(updateCustomerSignInStatus(false));
        dispatch(updateCustomerDetails({}));

        SalesDepartmentDispatcher.then(({ default: dispatcher }) => dispatcher.clearSalesDepartment(dispatch));
        PartnersDispatcher.then(({ default: dispatcher }) => dispatcher.clearPartners(dispatch));
    }

    forgotPassword(options = {}, dispatch) {
        const mutation = MyAccountQuery.getForgotPasswordMutation(options);

        return fetchMutation(mutation).then(
            /** @namespace RokitaBasic/Store/MyAccount/Dispatcher/MyAccountDispatcher/forgotPassword/fetchMutation/then/dispatch */
            () => dispatch(updateCustomerPasswordForgotStatus()),
            /** @namespace RokitaBasic/Store/MyAccount/Dispatcher/MyAccountDispatcher/forgotPassword/fetchMutation/then/catch */
            (error) => Promise.reject(getErrorMessage(error))
        );
    }

    async initialCart(dispatch, { fetchCart } = { fetchCart: false }) {
        try {
            await CartDispatcher.then(({ default: dispatcher }) =>
                /** @namespace RokitaBasic/Store/MyAccount/Dispatcher/MyAccountDispatcher/signIn/CartDispatcher/then */
                dispatcher.updateInitialCartData(dispatch, { fetchCart })
            );
            await TradeAgreementCartDispatcher.then(
                /** @namespace RokitaBasic/Store/MyAccount/Dispatcher/MyAccountDispatcher/signIn/TradeAgreementCartDispatcher/then */
                ({ default: dispatcher }) => dispatcher.updateInitialCartData(dispatch, { fetchCart })
            );
        } catch (error) {
            dispatch(showNotification('error', getErrorMessage(error), error));
        }
    }

    async resetCart(dispatch) {
        dispatch(updateIsLoading(true));

        try {
            await executePost(prepareMutation(MyAccountQuery.getDeleteCartMutation()));
            await this.initialCart(dispatch);
            dispatch(showNotification('success', __('Cart has been cleared. Add the product again')));
        } catch (error) {
            dispatch(showNotification('error', getErrorMessage(error)));
        }

        dispatch(updateIsLoading(false));
    }

    handleCustomerDataOnInit() {
        if (isSignedIn()) {
            return;
        }

        this.clearStorage();
    }

    clearStorage() {
        if (window.dataCache) {
            window.dataCache = {};
        }

        this.showTwoFactorVerification = false;
        this.token = null;

        SessionDatabase.deleteItem(SESSION_TOKEN);
        BrowserDatabase.deleteItem(AUTH_TOKEN);
        BrowserDatabase.deleteItem(AUTHORITIES);
        BrowserDatabase.deleteItem(CUSTOMER);
        BrowserDatabase.deleteItem(TRADE_AGREEMENT_CART_TOTALS);
        BrowserDatabase.deleteItem(CART_ID);
        BrowserDatabase.deleteItem(CART_TOTALS);
        BrowserDatabase.deleteItem(COMPARE_UID);
    }
}

export default new MyAccountDispatcher();
